import React from 'react';
import styled, { css } from 'styled-components';

import StockCardSlider from '@/components/StockCarCard/components/StockCardSlider';
import ArrowRightIcon from '@/assets/svg/more_details_arrow_right.svg';
import {
  Component as CarPriceComponent,
  Price as CarPriceAmount,
  Currency as CarPriceCurrency,
} from '@/components/CarPrice/CarPrice';
import {
  Label as PriceInfoLabel,
  Price as PriceInfoPrice,
  Prefix as PriceInfoPrefix,
  Icon as PriceInfoIcon,
} from '@/components/CarPrice/components/PriceInfo/PriceInfo';
import Share, { DropDownMenu } from '@/components/Share';
import MobileStockCardSlider from '@/components/StockCarCard/components/MobileStockCardSlider';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import CarTags from '@/components/CarTags';
import CarBadges from '@/components/CarBadges';
import CarPrice from '@/components/CarPrice/CarPrice';

import { CarPortraitViewProps } from './types';

function CarPortraitView({
  view,
  link,
  name,
  subName,
  buttonText = 'Получить предложение',
  buttonHandle,
  images,
  imageFit,
  price,
  tags,
  totalImagesCount,
  isCompared,
  onCompareChange,
  badgesList,
  carStatus,
  stockStatus,
  approved,
  grade,
  isLaptop,
  isMobile,
}: CarPortraitViewProps) {
  return (
    <Component>
      {link && <CardLink to={link} />}

      {isMobile ? (
        <MobileStockCardSlider
          imageFit={imageFit}
          images={images}
          alt={name}
          link={link}
          totalImagesCount={totalImagesCount}
          isCompared={isCompared}
          onCompareChange={onCompareChange}
          carStatus={carStatus}
          stockStatus={stockStatus}
          approved={approved}
          grade={grade}
          isMobile={isMobile}
        />
      ) : (
        <StockCardSlider
          imageFit={imageFit}
          images={images}
          alt={name}
          view={view}
          link={link}
          totalImagesCount={totalImagesCount}
          isCompared={isCompared}
          onCompareChange={onCompareChange}
          carStatus={carStatus}
          stockStatus={stockStatus}
          approved={approved}
          grade={grade}
          isLaptop={isLaptop}
        />
      )}

      <CardInfo isLaptop={isLaptop} isMobile={isMobile}>
        <CardInfoItem>
          <TitleWrapper>
            <CardTitle isLaptop={isLaptop} isMobile={isMobile}>
              {name}
            </CardTitle>
          </TitleWrapper>
          {subName && (
            <Subtitle isLaptop={isLaptop} isMobile={isMobile}>
              {subName}
            </Subtitle>
          )}

          <CarTagsWrapper isMobile={isMobile}>
            <CarTags tags={tags} isLaptop={isLaptop} isMobile={isMobile} />
          </CarTagsWrapper>

          {badgesList && (
            <CarBadgesWrapper>
              <CarBadges
                {...badgesList}
                isLaptop={isLaptop}
                isMobile={isMobile}
              />
            </CarBadgesWrapper>
          )}
        </CardInfoItem>
        <CardInfoItem>
          <PriceWrapper
            isLaptop={isLaptop}
            isMobile={isMobile}
            isCredit={!!price.credit}
            isFullPrice={!!(price.amount && price.discount && price.credit)}
          >
            <CarPrice {...price} isMobile={isMobile || isLaptop} />
          </PriceWrapper>

          {buttonHandle && (
            <CarCardButton
              onClick={buttonHandle}
              isLaptop={isLaptop}
              isMobile={isMobile}
            >
              {buttonText}
            </CarCardButton>
          )}

          {link && (
            <AboutModelWrapper isLaptop={isLaptop} isMobile={isMobile}>
              <AboutModel to={link} isLaptop={isLaptop} isMobile={isMobile}>
                Подробнее
                <Icon>
                  <ArrowRightIcon />
                </Icon>
              </AboutModel>
            </AboutModelWrapper>
          )}
        </CardInfoItem>

        {link && (
          <ShareWrapper
            className="disabled-modal"
            isLaptop={isLaptop}
            isMobile={isMobile}
          >
            <Share path={link} image={images[0]?.url} />
          </ShareWrapper>
        )}
      </CardInfo>
    </Component>
  );
}

export default CarPortraitView;

const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.gray500};
`;

const CardLink = styled(Link)`
  z-index: 1;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;

const CardTitle = styled.p<{ isLaptop?: boolean; isMobile?: boolean }>`
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  white-space: nowrap;
  color: ${(props) => props.theme.black};

  &::after {
    content: '';
    background: ${(props) => props.theme.whiteGradient};
    right: 0;
    top: 0;
    bottom: 0;
    width: 147px;
    height: 100%;
    display: block;
    position: absolute;
    pointer-events: none;
  }

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      font-size: 19px;
      line-height: 22px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 16px;
      line-height: 14px;
    `}
`;

const TitleWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const Subtitle = styled.div<{ isLaptop?: boolean; isMobile?: boolean }>`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  margin-top: 5px;
  color: ${(props) => props.theme.black};

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      font-size: 14px;
      line-height: 16px;
    `}
  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 10px;
      line-height: 12px;
    `};
`;

const CarTagsWrapper = styled.div<{ isMobile?: boolean }>`
  z-index: 1;
  margin-top: 24px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 15px;
    `}
`;

const CarBadgesWrapper = styled.div`
  z-index: 1;
  margin-top: 10px;
`;

const CardInfo = styled.div<{ isLaptop?: boolean; isMobile?: boolean }>`
  position: relative;
  background: ${(props) => props.theme.white_1};
  padding: 25px 20px 20px;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      padding: 27px 15px 17px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 19px 12px 12px;
    `}
`;

const CardInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CarCardButton = styled.button<{ isLaptop?: boolean; isMobile?: boolean }>`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${colors.white};
  background-color: ${colors.main};
  border-radius: 20px;
  width: 100%;
  padding: 13px 0;
  height: 52px;
  transition: background-color 0.1s ease-in-out;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  margin-top: 23px;
  text-transform: uppercase;

  &:hover {
    background-color: ${colors.main100};
  }

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      padding: 10px 0;
      height: 40px;
      font-size: 17px;
      line-height: 20px;
      margin-top: 16px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 8px 0;
      height: 32px;
      font-size: 14px;
      line-height: 16px;
      margin-top: 15px;
    `}
`;

const AboutModelWrapper = styled.div<{
  isLaptop?: boolean;
  isMobile?: boolean;
}>`
  display: flex;
  justify-content: center;
  margin-top: 23px;

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      margin-top: 20px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 15px;
    `}
`;

const AboutModel = styled(Link)<{ isLaptop?: boolean; isMobile?: boolean }>`
  z-index: 1;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.theme.black};

  &:hover {
    color: ${colors.main};

    & div > svg {
      fill: ${colors.main100};
    }
  }

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      font-size: 15px;
      line-height: 17px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 12px;
      line-height: 14px;
    `}
`;

const Icon = styled.div`
  padding-top: 2px;
  margin-left: 5px;
  cursor: pointer;

  svg {
    fill: ${(props) => props.theme.black100};
  }
`;

const ShareWrapper = styled.div<{
  isLaptop?: boolean;
  isMobile?: boolean;
}>`
  z-index: 1;
  position: absolute;
  left: 20px;
  bottom: 20px;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  ${DropDownMenu} {
    padding-left: 15px;
  }

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      width: 18px;
      height: 18px;
      left: 15px;
      bottom: 17px;

      & > div > button {
        width: 18px;
        height: 18px;
      }
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 15px;
      height: 15px;
      left: 12px;
      bottom: 14px;

      & > div > button {
        width: 15px;
        height: 15px;
      }
    `}
`;

const PriceWrapper = styled.div<{
  isLaptop?: boolean;
  isMobile?: boolean;
  isCredit?: boolean;
  isFullPrice?: boolean;
}>`
  z-index: 1;
  margin-top: 22px;

  @media (min-width: 1823px) and (max-width: 1878px) {
    ${CarPriceAmount} {
      font-size: 34px;
      line-height: 40px;
    }

    ${PriceInfoPrice} {
      font-size: 26px;
      line-height: 30px;
    }
  }

  @media (min-width: 1791px) and (max-width: 1822px) {
    ${CarPriceAmount} {
      font-size: 32px;
      line-height: 38px;
    }

    ${PriceInfoPrice} {
      font-size: 24px;
      line-height: 28px;
    }
  }

  @media (min-width: 1759px) and (max-width: 1790px) {
    ${CarPriceAmount} {
      font-size: 30px;
      line-height: 36px;
    }

    ${PriceInfoPrice} {
      font-size: 22px;
      line-height: 26px;
    }
  }

  @media (min-width: 1728px) and (max-width: 1758px) {
    ${CarPriceAmount} {
      font-size: 28px;
      line-height: 34px;
    }

    ${PriceInfoPrice} {
      font-size: 20px;
      line-height: 24px;
    }
  }

  @media (min-width: 1640px) and (max-width: 1727px) {
    ${CarPriceAmount} {
      padding-right: 13px;
      font-size: 26px;
      line-height: 32px;
    }

    ${PriceInfoLabel} {
      font-size: 13px;
      line-height: 15px;
    }

    ${PriceInfoPrice} {
      font-size: 18px;
      line-height: 22px;
    }
  }

  @media (min-width: 1562px) and (max-width: 1639px) {
    ${CarPriceAmount} {
      padding-right: 13px;
      font-size: 24px;
      line-height: 30px;
    }

    ${CarPriceCurrency} {
      font-size: 16px;
      line-height: 20px;
    }

    ${PriceInfoLabel} {
      font-size: 12px;
      line-height: 14px;
    }

    ${PriceInfoPrice} {
      font-size: 18px;
      line-height: 22px;
    }
  }

  @media (min-width: 1475px) and (max-width: 1561px) {
    ${CarPriceAmount} {
      padding-right: 13px;
      font-size: 22px;
      line-height: 26px;
    }

    ${CarPriceCurrency} {
      font-size: 16px;
      line-height: 20px;
    }

    ${PriceInfoLabel} {
      font-size: 12px;
      line-height: 14px;
    }

    ${PriceInfoPrice} {
      font-size: 18px;
      line-height: 22px;
    }

    ${PriceInfoPrefix} {
      font-size: 14px;
      line-height: 16px;
    }
  }

  @media (min-width: 1416px) and (max-width: 1474px) {
    ${CarPriceAmount} {
      padding-right: 13px;
      font-size: 22px;
      line-height: 26px;
    }

    ${CarPriceCurrency} {
      font-size: 14px;
      line-height: 16px;
    }

    ${PriceInfoLabel} {
      font-size: 11px;
      line-height: 13px;
    }

    ${PriceInfoPrice} {
      font-size: 16px;
      line-height: 20px;
    }

    ${PriceInfoPrefix} {
      font-size: 14px;
      line-height: 16px;
    }
  }

  @media (min-width: 1357px) and (max-width: 1415px) {
    ${CarPriceAmount} {
      padding-left: 4px;
      padding-right: 10px;
      font-size: 22px;
      line-height: 26px;
    }

    ${CarPriceCurrency} {
      font-size: 12px;
      line-height: 14px;
    }

    ${PriceInfoLabel} {
      font-size: 11px;
      line-height: 13px;
    }

    ${PriceInfoPrice} {
      font-size: 16px;
      line-height: 20px;
    }

    ${PriceInfoPrefix} {
      font-size: 13px;
      line-height: 15px;
    }

    ${PriceInfoIcon} {
      width: 9px;
      height: 9px;
    }
  }

  @media (min-width: 1314px) and (max-width: 1356px) {
    ${CarPriceAmount} {
      padding-left: 4px;
      padding-right: 10px;
      font-size: 22px;
      line-height: 26px;
    }

    ${CarPriceCurrency} {
      font-size: 12px;
      line-height: 14px;
    }

    ${PriceInfoLabel} {
      font-size: 10px;
      line-height: 12px;
    }

    ${PriceInfoPrice} {
      font-size: 15px;
      line-height: 18px;
    }

    ${PriceInfoPrefix} {
      font-size: 13px;
      line-height: 15px;
    }

    ${PriceInfoIcon} {
      width: 9px;
      height: 9px;
    }
  }

  @media (min-width: 1260px) and (max-width: 1313px) {
    ${CarPriceAmount} {
      padding-left: 4px;
      padding-right: 10px;
      font-size: 21px;
      line-height: 25px;
    }

    ${CarPriceCurrency} {
      font-size: 12px;
      line-height: 14px;
    }

    ${PriceInfoLabel} {
      font-size: 9px;
      line-height: 11px;
    }

    ${PriceInfoPrice} {
      font-size: 15px;
      line-height: 18px;
    }

    ${PriceInfoPrefix} {
      font-size: 9px;
      line-height: 11px;
    }

    ${PriceInfoIcon} {
      width: 9px;
      height: 9px;
    }
  }

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      margin-top: 20px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 15px;
    `}

  ${({ isFullPrice, isCredit }) =>
    (isFullPrice || isCredit) &&
    css`
      ${CarPriceComponent} {
        justify-content: space-between;
      }
    `}
`;

import React from 'react';
import styled from 'styled-components';

import { CarBadgesProps } from './types';
import {
  BadgeLocation,
  BadgeNovelty,
  BadgePartnerCar,
  BadgePurchaseOnline,
  BadgeTestDrive,
  BadgeVideoReview,
  BadgeVat,
  BadgeOnlyOnline,
  BadgeGuarantee,
  BadgeVersion,
} from './components';

function CarBadges({
  location,
  testDrive,
  vat,
  novelty,
  onlyOnline,
  videoReview,
  partnerCar,
  guarantee,
  purchaseOnline,
  version,
  isLaptop,
  isMobile,
}: CarBadgesProps) {
  return (
    <Component>
      {novelty && <BadgeNovelty isLaptop={isLaptop} isMobile={isMobile} />}
      {location && location.length > 0 && (
        <BadgeLocation
          locations={location}
          isLaptop={isLaptop}
          isMobile={isMobile}
        />
      )}
      {testDrive && <BadgeTestDrive isLaptop={isLaptop} isMobile={isMobile} />}
      {vat && <BadgeVat isLaptop={isLaptop} isMobile={isMobile} />}
      {onlyOnline && (
        <BadgeOnlyOnline isLaptop={isLaptop} isMobile={isMobile} />
      )}
      {videoReview && (
        <BadgeVideoReview
          video={videoReview}
          isLaptop={isLaptop}
          isMobile={isMobile}
        />
      )}
      {partnerCar && (
        <BadgePartnerCar isLaptop={isLaptop} isMobile={isMobile} />
      )}
      {guarantee && <BadgeGuarantee isLaptop={isLaptop} isMobile={isMobile} />}
      {purchaseOnline && (
        <BadgePurchaseOnline isLaptop={isLaptop} isMobile={isMobile} />
      )}
      {version && (
        <BadgeVersion
          label={version.name}
          hint={version.hint}
          isLaptop={isLaptop}
          isMobile={isMobile}
        />
      )}
    </Component>
  );
}

export default CarBadges;

const Component = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-bottom: 5px;
  }

  & > div:not(:last-child) {
    margin-right: 5px;
  }
`;

import React from 'react';

import { StockCarCardProps } from './types';
import CarPortraitView from './components/CarPortraitView';
import CarLandscapeView from './components/CarLandscapeView';

function StockCarCard({
  view,
  link,
  name,
  subName,
  buttonText,
  buttonHandle,
  images,
  imageFit,
  price,
  tags,
  badgesList,
  totalImagesCount,
  isCompared,
  onCompareChange,
  carStatus,
  stockStatus,
  approved,
  grade,
  isLaptop,
  isMobile,
}: StockCarCardProps) {
  return view === 'portrait' ? (
    <CarPortraitView
      view={view}
      buttonText={buttonText}
      buttonHandle={buttonHandle}
      images={images}
      imageFit={imageFit}
      link={link}
      name={name}
      subName={subName}
      price={price}
      tags={tags}
      badgesList={badgesList}
      totalImagesCount={totalImagesCount}
      isCompared={isCompared}
      onCompareChange={onCompareChange}
      carStatus={carStatus}
      stockStatus={stockStatus}
      approved={approved}
      grade={grade}
      isLaptop={isLaptop}
      isMobile={isMobile}
    />
  ) : (
    <CarLandscapeView
      view={view}
      buttonText={buttonText}
      buttonHandle={buttonHandle}
      images={images}
      imageFit={imageFit}
      link={link}
      name={name}
      subName={subName}
      price={price}
      tags={tags}
      badgesList={badgesList}
      totalImagesCount={totalImagesCount}
      isCompared={isCompared}
      onCompareChange={onCompareChange}
      carStatus={carStatus}
      stockStatus={stockStatus}
      approved={approved}
      grade={grade}
    />
  );
}

export default StockCarCard;

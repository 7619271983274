import React from 'react';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser } from '@tager/web-core';

import { colors } from '@/constants/theme';

import { CarTagsProps } from './CarTags.types';
import VinTag from './components/VinTag';
import InfoText from './components/InfoText';

function CarTags({ tags, VIN, isMobile, isLaptop, className }: CarTagsProps) {
  return (
    <Component className={className}>
      {tags.map(({ title, background = colors.gray100, infoText }, index) => {
        return (
          <Tippy
            key={index}
            content={infoText && <InfoText text={infoText} />}
            placement="bottom"
            interactive
            appendTo={isBrowser() ? document.body : 'parent'}
          >
            <CarTagsItem
              isLaptop={isLaptop}
              isMobile={isMobile}
              background={background}
            >
              {title}
            </CarTagsItem>
          </Tippy>
        );
      })}
      {VIN && <VinTag vin={VIN} isLaptop={isLaptop} isMobile={isMobile} />}
    </Component>
  );
}

export default CarTags;

const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CarTagsItem = styled.div<{
  isLaptop?: boolean;
  isMobile?: boolean;
  background?: string;
}>`
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;

  &:not(:last-child) {
    margin-right: 5px;
  }

  ${({ isLaptop }) =>
    isLaptop &&
    css`
      padding: 7.5px 7px;
      font-size: 12px;
      line-height: 14px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 6px;
      font-size: 8px;
      line-height: 9px;
    `}
  
  ${({ background }) =>
    background &&
    css`
      background: ${background};
    `}
`;

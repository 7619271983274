import React from 'react';
import styled from 'styled-components';

import CardIcon from '@/assets/svg/guarantee.svg';

import Badge from '../Badge';

import { BadgeGuaranteeProps } from './types';

function BadgeGuarantee({
  label = 'Авто на гарантии',
  isLaptop,
  isMobile,
}: BadgeGuaranteeProps) {
  return (
    <StyledBadge
      label={label}
      icon={<CardIcon />}
      isLaptop={isLaptop}
      isMobile={isMobile}
    />
  );
}

export default BadgeGuarantee;

const StyledBadge = styled(Badge)`
  color: #9b51e0;
  background-color: #f4e8ff;
`;
